.card{
    height: 80vh;
    max-height: 600px;
    width: 480px;
    border-radius: 36px;
    background: #FFF;
    padding: 20px 15px;
    /* background-image: url('../../../Assets/authCardBG.svg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 51%; */
}

.LogoutBTN{
    padding: 10px 20px;
    
}

@media (max-width: 768.99px) {
    .card{
        height: 70vh;
        width: auto;
        /* margin: 0px 15px; */
    }
}