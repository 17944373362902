.scale-in-top {
	-webkit-animation: scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes scale-in-top {
    0% {
      -webkit-transform: scaleY(0.5);
              transform: scaleY(0.5);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 1;
    }
  }
  @keyframes scale-in-top {
    0% {
      -webkit-transform: scaleY(0.5);
              transform: scaleY(0.5);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 1;
    }
  }
  
.rotate-in-2-cw {
	-webkit-animation: rotate-in-2-cw 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: rotate-in-2-cw 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes rotate-in-2-cw {
    0% {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
      opacity: 1;
    }
  }
  @keyframes rotate-in-2-cw {
    0% {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
      opacity: 1;
    }
  }
  