.lookupData {
    overflow: auto; /* make the table scrollable if height is more than 200 px  */
    height: 80vh; /* gives an initial height of 200px to the table */
    width: 70vw;
}

.lookupTypeTable{
    border-collapse: separate;
    border-spacing: 0px 8px;
}

table{
  border-collapse: collapse;
}

.tableHeader{
    border-radius: 12px 12px 12px 12px;
    height: 40px;
    background-color: #801F52 !important;
    color: #FFF;
    position: sticky; /* make the table heads sticky */
    top: 0px; /* table head will be placed from the top of the table and sticks to it */
    z-index: 10;
}

.tableHeader th{
    text-align: left;
    min-width: 130px;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    padding-right: 20px;
}

.tableHeader th:first-child{
    border-radius: 12px 0px 0px 12px;
}

.tableHeader th:last-child{
    border-radius: 0px 12px 12px 0px;
}

.tableBody{
    min-height: auto;
    max-height: 70vh;
    overflow: scroll;
}

.tableBody .tableBodyRow{
    border-radius: 50px 50px 50px 50px;
    min-height: 40px;
    box-shadow: -1px 1px 0px 0px #00000014;
    border: none;
}

th, td {
    &:first-child {
      position: -webkit-sticky;
      position: sticky;
      left: 0px;
      z-index: 2;
      min-width: 50px;
    }
}

th {
    &:last-child {
        background-color: #801F52;
    }
}

th, td {
    &:last-child {
      position: -webkit-sticky;
      position: sticky;
      right: -14px;
      z-index: 2;
      min-width: 100px;
    }
}

.tableBodyRow td{
    text-align: left;
    min-width: 50px;
    margin: 10px;
    color: #221F4B;
    padding-right: 10px;
    font-size: 16px;
}

.tableBodyRow:nth-child(even) td{
  background-color: #FFEEE4 !important;
}

.tableBodyRow:nth-child(odd) td{
  background-color: #FEDEC9 !important;
}

.tableBodyRow td:first-child{
    border-radius: 10px 0px 0px 10px;
    text-align: center;
}

.tableBodyRow td:last-child{
    border-radius: 0px 10px 10px 0px;
    text-align: end;
    padding-right: 20px;
    border: none;
}

.actionCol {
    position: sticky;
    width: 5em;
    left: 0;
    top: auto;
    border-top-width: 1px;
    /*only relevant for first row*/
    margin-top: -1px;
    /*compensate for top border*/
}

.actionCol img:hover{
    transform: scale(1.2);
}

.VeritacalSeprator{
    width: 0.5px;
    height: 25px;
    margin: 0px 10px;
    background-color: #94B1CB;
}

  /* Style the scrollbar track on Firefox */
  .lookupData {
    scrollbar-width: thin;
    scrollbar-color: #FFF #f0f0f0;
  }

  
/* Style the scrollbar track */

.lookupData::-webkit-scrollbar{
  width: 14px;
  height: 14px;
  border-radius: 10px;
}

.lookupData::-webkit-scrollbar-track{
  background-color: #801F52;
  border-radius: 10px;
}

  /* Style the scrollbar thumb */
  .lookupData::-webkit-scrollbar-thumb {
    background: #FFF;
    border-radius: 10px;
    width: 2px !important;
    margin: 2px;
  }
  
  
  ::-webkit-scrollbar-thumb{
    width: 18px;
    background: #F3F9FF;
    border: 5px solid #801F52;
    border-radius: 15px;
  }

  .submitButton{
    background-color: #221F4B;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 16px;
    border-radius: 10px;
  }

  .keepItButton {
    border: 1.5px solid #221F4B;
    background-color: #ffff;
    color: #221F4B;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 16px;
    border-radius: 10px;
  }

@media (max-width: 768px) {
  .lookupData{
    width: 100vw;
    height: 94vh;
  }
  .tableBodyRow td:first-child {
    padding-left: 10px;
    padding-top: 8px;
    min-width: 40px;
  }
  .tableHeader th:first-child{
    padding-left: 10px;
    padding-top: 8px;
    min-width: 30px;
  }

  .tableBodyRow td{
    font-size: 12px;
    min-width: 100px;
  }

  .tableHeader th{
    font-size: 12px;
    min-width: 100px;
  }
  .tableHeader th:last-child{
    min-width: 60px;
  }
  .tableBodyRow td:last-child{
    min-width: 60px;
    padding-right: 5px;
  }
  .tableBody .tableBodyRow{
    height: 45px;
  }
  .submitButton{
    font-size: 14px;
  }
  
  .keepItButton {
    font-size: 14px;
  }
  .tableHeader{
    height: 45px;
  }
}

.padTop-0{
  padding-top: 0px !important;
}