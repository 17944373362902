.portfolioImage{
    width: 30px;
    height: 30px;
    box-shadow: 4px 4px 30px 0px #00000026;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ticketSection{
    overflow-y: auto;
}
.ticketDetailsSection{
    border: 0.54px solid #73BDFF;
    border-radius: 2.15px;
    padding: 5px;
    margin-top: 5px;
}

.ticketDetailsFormSection{
    border: 1px solid #73BDFF;
    border-radius: 4px;
    padding: 10px 20px;
    margin-top: 20px;
}

.formPortfolioPicSection{
    width: 40px;
    height: 40px;
    box-shadow: 4px 4px 30px 0px #00000026;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}
