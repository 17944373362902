@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



::-webkit-scrollbar{
  width: 14px;
  border-radius: 20px;
}

::-webkit-scrollbar-track{
background: #801F52;
border-radius: 20px;
}
::-webkit-scrollbar-thumb{
width: 18px;
background: #FFF;
border: 5px solid #801F52;
border-radius: 15px;
}

.loader {
  height: 6px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background:
   linear-gradient(#FF914E 0 0) 0/0% no-repeat
   #801F52;
  animation: l2 2.5s infinite steps(150);
}
@keyframes l2 {
    100% {background-size:110%}
}