.cardLogin{
    height: 80vh;
    max-height: 600px;
    width: 480px;
    border-radius: 36px;
    background: #FFF;
    background-image: url('../../../Assets/authCardBG.svg');
    padding: 31px 31px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 90%;
}
.loginText{
    color: #14304E;
    font-weight: 600;
    font-size: 48px;
    text-align: left;
    margin: 10px 0px;
}

.signInButton{
    width: 100%;
    border: 1.5px solid #FF914E;
    background: #fff;
    padding: 10px 0px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: #FF914E;
    margin: 20px 0px;
    line-height: 19.76px;
    height: 60px;
}

.googleImage{
    background-image: url('../../../Assets/googleColored.svg');
    height: 25px;
    width: 25px;
    background-position: center;
    background-size: cover;
    margin-right: 10px;
}

.signInButton:hover{
    background-color: #FF914E;
    color: #fff;
    
    .googleImage{
        background-image: url('../../../Assets/googleWhite.svg');
    }
}

@media (max-width:768.99px) {
    .cardLogin{
        /* background-image: none; */
        height: 70vh;
        width: auto;
        /* padding: 30px 34px;
        margin: 0px 15px; */
    }
    .loginText{
        font-size: 32px;
        text-align: center;
        margin: 0px;
    }
}