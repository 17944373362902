*{
  font-family: 'Poppins',sans-serif;
}

.dashBoardBody{
    /* height: 100vh; */
    background-color: #181818;
    background-image: url('../../../Assets/desktopBG.png');
    background-repeat: repeat;
    background-size: contain;
}

.dashboardHeading{
    font-weight: 700;
    font-size: 28px;
    line-height: 34.59px;
    text-align: center;
}

.sideBarBG{
    background-color: rgba(225, 242, 255, 0.274);
    height: 95%;
    border-radius: 15px;
    width: 310px;
}

.ProfileSection{
    background-color: #FF914E;
    border-radius: 15px;
}

.sideProfilePic{
  height: 50px;
  width: 50px;
  border-radius: 50px;
}

.ProfileName{
    font-size: 16px;
    font-weight: 700;
}

.ProfileDesignation{
    font-weight: 500;
    font-size: 12px;
}

.menu {
  width: 100%;
  padding: 0px 3px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin: 1px 0px;
  }

  li>span {
    padding: 5px 0px;
  }
  
  
  a {
    text-decoration: none;
    color: #FFF;
    font-weight: 400;
    font-size: 14px;
    line-height: 22.23px;
    padding: 0px 10px;
    font-family: 'Poppins',sans-serif;
    cursor: pointer;
  }

  a:hover{
    color: #FF914E;
  }

  li.active{
    /* background-color: #FFECE4; */
    padding: 5px 0px;
    border-radius: 10px;
  }

  li.active a{
      color: #FF914E;
      font-weight: 500;
    }
    
  .menu-label:hover {
    color: #FF914E;
  }
  .menu-label {
    display: block;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;
    line-height: 19.76px;
    font-weight: 400;
    font-size: 14px;
    padding: 10px 0px;
    padding-right: 10px;
  }
  
  .menu-label.open{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #FFF;
    color: #FF914E;
  }
  
  .sub-menu-label:hover {
    color: #FF914E;
  }

  .sub-menu-label {
    display: block;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;
    line-height: 19.76px;
    font-weight: 500;
    font-size: 14px;
    padding: 5px 11px;

    .dot{
      font-size: 20px;
      margin-right: 10px;
      margin-left: -20px;
    }

  }

  .sub-menu .active{
    padding: 5px 12px;
  }
  
  .arrow {
    margin-left: 5px;
  }
  
  .sub-menu {
    list-style-type: none;
    padding: 0;
    margin-left: 20px;
    display: none;
  }

  .subMenuText{
    padding-left: 10px;
  }

  .sub-menu>ul{
    padding-bottom: 6px;
  }

  .sub-menu>li{
    margin: 2px 0px;
  }
  
  .sub-menu.open {
    display: block;
  }

/* Scroll bar stylings */

.menu::-webkit-scrollbar{
  width: 14px;
  border-radius: 20px;
}

.menu::-webkit-scrollbar-track{
background: #801F52;
border-radius: 20px;
}
::-webkit-scrollbar-thumb{
width: 18px;
background: #FFECE4;
border: 5px solid #801F52;
border-radius: 15px;
}

.SubMenuOpen{
  background: #FFFFFF6B;
  border-radius: 10px;
}
  

.labelHeadeing{
    font-weight: 700;
    font-size: 16px;
    color: #FFECE4;
    line-height: 24.7px;
    padding: 10px;
}

.OutletContainer{
    background-color: #FFECE4;
    border-radius: 15px;
}

.seprator{
  height: 0.5px;
  background: #FFECE4;
}

/* .logoutSection{
  background-color: #3e74a0;
} */

.dashboardTable>th:first-child, .dashboardTable>td:first-child
{
  padding-left: 15px;
  position: static;
}
.dashboardTable>th:last-child, .dashboardTable>td:last-child
{
  position: static;
}
.dashboardTable>th {
  font-size: 14px;
  font-weight: 600;
}
.dashboardTable>td:first-child
{
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.dashboardTable>td:last-child
{
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.dashboardTable>td
{
  font-size: 14px;
  font-weight: 500;
  color: '#221F4B';
}
.dashboardTable:nth-child(even) td{
  background-color: #FFEEE4;
}
.dashboardTable:nth-child(odd) td{
  background-color: #FEDEC9;
}

@media (max-width: 768px) {
  .dashboardHeading{
    font-size: 24px;
    line-height: 29.65px;
  }
  .sideBarBG{
    height: 482px;
    border-radius: 12px;
    width: 220px;
  }
  .OutletContainer{
    border-radius: 0px;
  }
  .ProfileSection{
    border-radius: 5px;
  }
  .sideProfilePic{
    height: 36px;
    width: 36px;
  }
  .ProfileName{
    font-size: 14px;
  }
  .labelHeadeing{
    font-size: 14px;
    padding: 0px 10px;
  }
  li{
    margin: 7px 0px;
  }
  li.active{
    padding: 0px;
    padding-left: 5px;
  }
  a{
    font-size: 12px;
    line-height: 14.82px;
    padding: 5px;
  }
  .menu-label{
    font-size: 12px;
    padding: 5px;
  }
  .sub-menu-label{
    font-size: 12px;
    line-height: 14.82px;
  }
}

@media (max-width: 768px) {
  .OutletContainer{
    height: 100vh;
    padding-top: 5px;
  }
  .dashboardTable>th {
    font-size: 12px;
  }
  .dashboardTable>td
  {
    font-size: 12px;
  }
}