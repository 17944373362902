.body{
    margin: 0px;
    padding: 0px;
    height: 100vh;
    width: 100vw;
    background-color: #181818;
    background-image: url('../../Assets/authBG.png') ;
    text-align: center;
    position: relative;
    font-family: 'Poppins', sans-serif;
    background-position: bottom;
    background-repeat: repeat;
    background-size: 100%;
}
.childBG{
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
}
.sections{
    display: flex;
    flex-direction: row;
    /* padding: 60px 0px; */
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    overflow-y: auto;
}
.leftSection{
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leftSectionImage{
    height: 270px;
    width: 434px;
}
.favcyText{
    color: #FF914E;
    font-weight: 600;
    font-size: 64px;
    margin-bottom: 0px;
    line-height: 79.05px;
}
.InterhubText{
    font-weight: 600;
    font-size: 64px;
    margin-top: 0px;
    color: #fff;
    line-height: 79.05px;
}
.rightSection{
    width: auto;
}

@media (max-width: 768.99px) {
    .body{
        text-align: center;
        position: relative;
        font-family: 'Poppins', sans-serif;
    }
    .sections{
        flex-direction: column;
        padding: 20px 0px;
    }
    .leftSection{
        width: 100%;
        height: auto;
    }
    .leftSectionImage{
        height: 150px;
        width: 60%;
    }
    .rightSection{
        width: 95%;
    }
    .favcyText,.InterhubText{
        font-size: 48px;
        line-height: 59.29px;
    }
}