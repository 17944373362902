.searchInput{
    background-color: #FBC7A4;
    height: 38px;
}
.messageInput{
    background-color: #FBC7A4;
    height: 50px;
    color: #221F4B;
    font-weight: 500;
    font-size: 14px;
    line-height: 24.7px;
    border-radius: 40px;
}
.messageInput::placeholder{
  color: #221F4B;
  font-weight: 500;
  font-size: 14px;
  line-height: 24.7px;
}
.leftSearchIcon{
    background-color: #801F52;
    color: #FFF;
    height: 38px;
    width: 38px;
    border-radius: 30px;
}
.headerProfileImage{
    height: 38px;
    width: 38px;
    border: 1px solid #221F4B;
    border-radius: 30px;
}
.notificationBtn{
    height: 38px;
    width: 38px;
    background-color: #801F52;
    color: #FFF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}
.chatMessages{
    background: #9f9ea600;
}
.sendBtn{
    height: 50px;
    width: 50px;
    color: #801F52;
    background-color: #FBC7A4;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}

.message__container {
    width: 100%;
    height: 99%;
    background-color: #FFECE4;
    padding: 20px 20px 0px 0px;
    overflow-y: scroll;
  }
  
  .message__container > * {
    margin-bottom: 10px;
  }
  .chat__footer {
    padding: 10px;
    background-color: #f9f5eb;
    height: 10vh;
  }

  .message__recipient {
    background-color: #0485E1;
    max-width: 45%;
    width: fit-content;
    padding: 10px 20px 10px 25px;
    border-radius: 20px 20px 20px 0px;
    font-size: 15px;
    margin-bottom: 7px;

  }

  .profile_pic{
    height: 40px;
    width: 40px;
    border: 0.5px solid #221F4B;
    border-radius: 20px;
    margin-right: 4px;
  }

  .recipient_name{
      color: #221F4B;
      margin-top: -3px;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 3px;
  }
    
.recipient_message{
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.76px;
}

.recipient_time{
    color: #BEE0FF;
    font-weight: 600;
    font-size: 14px;
    line-height: 17.29px;
    text-align: end;
    margin-top: 2px;
}

  .message__sender {
    background-color: #044075;
    max-width: 45%;
    width: fit-content;
    padding: 20px 20px 10px 25px;
    border-radius: 20px 20px 0px 20px;
    margin-left: auto;
    font-size: 15px;
    margin-bottom: 7px;
  }
  .sender_message{
    font-weight: 500;
    font-size: 16px;
    line-height: 19.76px;
    color: #fff;
  }
  .message__chats > p {
    font-size: 13px;
  }
  .sender__name {
    text-align: right;
    line-height: 19.76px;
  }
  .message__status {
    position: fixed;
    bottom: 80px;
    font-size: 13px;
    font-style: italic;
  }

  .message_chats_date{
    background: #FF914EE5;
    padding: 5px 25px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    line-height: 17.29px;
    margin-bottom: 10px;
  }

 /* Style the scrollbar track */
 .message__container::-webkit-scrollbar {
  width: 14px;
  height: 100%;
  background-color: #03467D;
  border-radius: 10px;
}

/* Style the scrollbar thumb */
.message__container::-webkit-scrollbar-thumb {
  background: #F3F9FF;
  border-radius: 10px;
  width: 2px !important;
  margin: 2px;
}

/* Style the scrollbar track on Firefox */
.message__container {
  scrollbar-width: thin;
  scrollbar-color: #F3F9FF #f0f0f0;
}

/* Style the scrollbar thumb on Firefox */
.message__container::-webkit-scrollbar-thumb {
  background: #F3F9FF;
}

/* Style the scrollbar thumb on Firefox */
.message__container::-webkit-scrollbar-thumb {
  background: #F3F9FF;
}

/* Optionally, you can style other scrollbar states (hover, active, etc.) */
.message__container::-webkit-scrollbar-thumb:hover {
  background: #F3F9FF;
}

.message__container::-webkit-scrollbar-thumb:active {
  background: #F3F9FF;
}

.avatar-row {
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0px 0px 0px 15px;
}

.avatar {
  width: 50px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-left: -30px; /* Adjust this value to control the overlap */
}

.avatar img {
  width: 38px;
  height: 38px;
  object-fit: cover;
}


@media (max-width: 768px) {
  .headerProfileImage{
    height: 38px;
    width: 56px;
  }
  .notificationBtn{
    height: 38px;
    width: 38px;
  }
  .searchInput{
    height: 36px;
  }
  .messageInput{
    height: 50px;
    font-size: 14px;
    line-height: 17.29px;
  }
  .messageInput::placeholder{
    font-size: 14px;
    line-height: 17.29px;
  }
  .chatScreen{
    padding-top: 5px;
    height: 100vh;
  }
  .leftSearchIcon{
    height: 38px;
    width: 45px;
  }
  .message__container::-webkit-scrollbar {
    width: 7px;
  }
  .message__sender{
    max-width: 85%;
  }
  .message__recipient{
    max-width: 85%;
  }
  .profile_pic{
    height: 30px;
    width: 30px;
  }
}