.AttributesHeader{
    background-color: #801F52;
    color: #ffffff;
    border-radius: 50px;
}

.addAttributeBtn{
    background-color: #FBC7A4;
    height: 50px;
    width: 50px;
    color: #F3F9FF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}

.attributeSection{
    overflow-y: auto;
}

.crossButton{
    height: 16px;
    width: 16px;
    background: #f00;
    border-radius: 20px;
    color: #f3f9ff;
    font-size: 10px;
}

.saveBtn{
    color: #FFF;
    background-color: #801F52;
    padding: 10px 30px 10px 30px;
    border-radius: 10px;
    line-height: 19.76px;
    margin-right: 10px;
    font-weight: 700;
}

.cancelBtn{
    border: 2px solid #801F52;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    line-height: 19.76px;
    margin-right: 10px;
    font-weight: 700;
    color: #801F52;
}

.fpmPortfolioVesionHeader{
    background-color: #FBC7A4;
    color: #801F52;
    border-radius: 50px;
}