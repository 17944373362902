.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* h1,h2,h3,h4,h5,h6,p{
  margin: 0px;
  padding: 0px;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.AttendanceTable {
  overflow: auto; /* make the table scrollable if height is more than 200 px  */
  background: #fff;
  padding: 0px 7px;
  border-radius: 14px;
}

/* Style the scrollbar track */

.AttendanceTable::-webkit-scrollbar{
  width: 14px;
  height: 14px;
  border-radius: 10px;
}

.AttendanceTable::-webkit-scrollbar-track{
  background-color: #c7c7c7;
  border-radius: 10px;
}

  /* Style the scrollbar thumb */
  .AttendanceTable::-webkit-scrollbar-thumb {
    background: #FFF;
    border-radius: 10px;
    width: 1px !important;
    margin: 2px;
  }

/* Style the scrollbar track */

.dashboardAttendanceTable::-webkit-scrollbar{
  width: 10px;
  border-radius: 10px;
}

.dashboardAttendanceTable::-webkit-scrollbar-track{
  background-color: #90909080;
  border-radius: 10px;
}

  /* Style the scrollbar thumb */
  .dashboardAttendanceTable::-webkit-scrollbar-thumb{
    width: 7px;
    background: #F3F9FF;
    border: 2px solid #90909080;
    border-radius: 15px;
  }