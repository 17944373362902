.AttributesHeader{
    background-color: #094B80;
    color: #ffffff;
    border-radius: 50px;
}

.addAttributeBtn{
    background-color: #94B1CB;
    height: 50px;
    width: 50px;
    color: #F3F9FF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}

.attributeSection{
    overflow-y: auto;
}

.crossButton{
    height: 16px;
    width: 16px;
    background: #f00;
    border-radius: 20px;
    color: #f3f9ff;
    font-size: 10px;
}

.attributeSection::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    background-color: #03467D;
    border-radius: 10px;
  }
  
  /* Style the scrollbar thumb */
  .attributeSection::-webkit-scrollbar-thumb {
    background: #FFF;
    border-radius: 10px;
    width: 2px !important;
    margin: 2px;
  }
  
  /* Style the scrollbar track on Firefox */
  .attributeSection {
    scrollbar-width: thin;
    scrollbar-color: #FFF #f0f0f0;
  }
  
  /* Style the scrollbar thumb on Firefox */
  .attributeSection::-webkit-scrollbar-thumb {
    background: #FFF;
  }
  
  /* Style the scrollbar thumb on Firefox */
  .attributeSection::-webkit-scrollbar-thumb {
    background: #FFF;
  }
  
  /* Optionally, you can style other scrollbar states (hover, active, etc.) */
  .attributeSection::-webkit-scrollbar-thumb:hover {
    background: #FFF;
  }
  
  .attributeSection::-webkit-scrollbar-thumb:active {
    background: #F3F9FF;
  }

.saveBtn{
    color: #F3F9FF;
    background-color: #221F4B;
    padding: 10px 30px 10px 30px;
    border-radius: 10px;
    line-height: 19.76px;
    margin-right: 10px;
    font-weight: 700;
}

.cancelBtn{
    border: 2px solid #221F4B;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    line-height: 19.76px;
    margin-right: 10px;
    font-weight: 700;
    color: #221F4B;
}